import React from 'react';
import { graphql } from 'gatsby';
import LegalPage from 'components/pages/LegalPage';

const seo = {
  title: 'Q°emotion - Information & Mentions légales',
  keywords: [],
  description:
    'Q°emotion est une start-up lancée en 2015 qui utilise les émotions pour vous permettre de réduire votre taux d’attrition. Demandez une démo gratuite maintenant.',
};

export default props => <LegalPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockText: markdownRemark(
      frontmatter: { pageType: { eq: "legalInformation" }, lang: { eq: "fr" } }
    ) {
      rawMarkdownBody
    }
  }
`;
